body {
    background-color: #504ECF;
    color: white;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.full-screen-flow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  align-items: center;
  text-align: center;

  font-size: 10pt;

  .step {
    height: 100%;
    width: 100%;
    padding: 70px 0 50px;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .module-dchat-logo {
    background: url('../public/static/images/dchat-logo.svg') no-repeat center;
    width: 8em;
    height: 8em;
  }
  .banner-image {
    margin: 1em;
    display: none;

    @media (min-height: 550px) {
      display: inline-block;
      height: 10em;
      width: 10em;
    }
  }
}

.App{
    display: flex;
    justify-content: center;
    .header {

    }
    .subheader{
        text-align: center;
    }

    .dchat-wallet-info-text{
      font-weight: 400;
      padding: 1em;
      font-size: 1.3em;
    }

    .wallet-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .dchat-wallet-button {
        cursor: pointer;
        display: flex;
        border: none;
        /* // min-width: 300px; */
        justify-content: space-between;
        align-items: center;
        margin-top: 39px;
        text-align: left;
        width: 327px;
        height: 52px;
        padding: 12px 22px;
        margin-top: 1em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        color: #504ECF;
        background: white;
        font-weight: 600;
        line-height: 28px;
        border-radius: 30px;
        font-size: 12pt;
      }
      .module-metamask-logo {
        background: url('../public/static/images/metamask.svg') no-repeat center;
        width: 35px;
        height: 35px;
      }
}